exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-ai-jsx": () => import("./../../../src/pages/ai.jsx" /* webpackChunkName: "component---src-pages-ai-jsx" */),
  "component---src-pages-cloud-jsx": () => import("./../../../src/pages/cloud.jsx" /* webpackChunkName: "component---src-pages-cloud-jsx" */),
  "component---src-pages-consultai-jsx": () => import("./../../../src/pages/consultai.jsx" /* webpackChunkName: "component---src-pages-consultai-jsx" */),
  "component---src-pages-consultcloud-jsx": () => import("./../../../src/pages/consultcloud.jsx" /* webpackChunkName: "component---src-pages-consultcloud-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-featureai-jsx": () => import("./../../../src/pages/featureai.jsx" /* webpackChunkName: "component---src-pages-featureai-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templates-usecase-jsx": () => import("./../../../src/templates/usecase.jsx" /* webpackChunkName: "component---src-templates-usecase-jsx" */)
}

